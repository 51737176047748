<template>
    <div class="shop_main">
        <StoreHeader :type="2" :backgroundImage="backgroundImage" :categoryList="categoryList" :storeName="storeName" :storeGoodsType="$route.query.type" :isRequestLoading="isRequestLoading" />

        <StoreGoods :type="2" :storeName="storeName" :corporatePhone="corporatePhone" :legalBusinessScope="legalBusinessScope" :showImageList="showImageList" :isCollect="isCollect" @toCollect="postStoreCollect" :goodsList="goodsList" />
    </div>
</template>

<script>
  import {getShopStore, getShopStoreCollect, postShopStoreCollect, getShopStoreGoods} from "../../../api/shop/store";
  import {getStoreInfo} from "../../../api/shop/goods";
  import {imageURL} from "../../../utils/config";
  import {mapState} from "vuex";

  const StoreHeader = () => import('./header');
  const StoreGoods = () => import('./goods');

  export default {
    name: "shopStoreType",
    data () {
      return {
        imageURL: imageURL,
        isRequestLoading: false,
        keyword: '',
        backgroundImage: '',
        categoryList: [],
        storeName: '',
        corporatePhone: '',
        legalBusinessScope: '',
        showImageList: [],
        isCollect: false,
        goodsList: []
      }
    },
    computed: {
      ...mapState({
        isLogin: state => state.user.isLogin
      })
    },
    created() {
      this.getInfo();
      this.getStoreInfo();
      if (this.isLogin) {
        this.getStoreCollect();
      }
      this.getShopStoreGoods()
    },
    methods: {
      getInfo () {
        this.isRequestLoading = true;
        getShopStore(this.$route.query.id).then(res => {
          this.isRequestLoading = false;
          if (res.code === 0) {
            this.categoryList = res.data.categoryList ? res.data.categoryList : [];
            this.backgroundImage = res.data.backgroundImage;
            this.storeName = res.data.storeName;
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },
      getStoreInfo () {
        getStoreInfo(this.$route.query.id).then(res => {
          if (res.code === 0) {
            this.corporatePhone = res.data.corporatePhone;
            this.legalBusinessScope = res.data.legalBusinessScope;
            this.showImageList = [imageURL + res.data.businessLicenseImage]
          }
        })
      },
      getStoreCollect () {
        getShopStoreCollect(this.$route.query.id).then(res => {
          if (res.code === 0) {
            this.isCollect = res.data;
          }
        })
      },
      postStoreCollect () {
        postShopStoreCollect(this.$route.query.id).then(res => {
          if (res.code === 0) {
            this.getStoreCollect();
          }
        })
      },
      getShopStoreGoods () {
        getShopStoreGoods({
          category: this.$route.query.type,
          storeId: this.$route.query.id
        }).then(res => {
          if (res.code === 0) {
            this.goodsList = res.data
          }
        })
      }
    },
    components: {
      StoreHeader, StoreGoods
    }
  }
</script>

<style lang="scss" scoped>
    @import "../../../assets/style/store";
</style>
